<style lang="sass" scoped>
.mainHeader
    border-bottom: 1px solid #ccc
.navs
    position: absolute
    left: 140px
    top: 0
    height: 60px
    line-height: 60px
.logo
    display: flex
    width: 208px
    justify-content: center
    align-items: center
    color: #fff
    img
        width: 120px
</style>
<style lang="sass">
.el-popover
    min-width: 100px !important
</style>
<template>
    <div class="header">
        <div class="logo">
            <img src="@/assets/img/upyun_logo.png" alt="">
        </div>
        <div class="header-right">
            <el-popover placement="bottom" width="80" trigger="hover">
                <router-link to="/set/account">账号信息</router-link>
                <hr style="border-color: #fff" />
                <a href="" @click="logout()">退出登录</a>
                <span class="cursor: pointer" slot="reference">{{
                    user.username
                }}</span>
            </el-popover>
        </div>
    </div>
</template>

<script>
export default {
    props: ["navNum"],
    data() {
        return {
            user: {}
        };
    },
    mounted() {
        this.user = JSON.parse(localStorage.getItem("user"));
    },
    methods: {
        gotoAccount() {
            this.$router.push({ name: "account" });
        },
        logout() {
            localStorage.clear();
        }
    },
    components: {}
};
</script>
