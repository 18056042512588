<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>

<script>
export default {
    name: "App"
};
</script>

<style lang="scss">
.el-button.el-button {
    height: 32px;
    border-radius: 4px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    padding: 0 16px;
}

.el-dropdown-menu {
    &.el-popper.el-popper li {
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;

        &:hover {
            color: #fff;
            background: #1479ff;
        }
    }
}

.el-loading-spinner .path.path {
    stroke: #1479ff;
}

.el-message.el-message {
    width: 296px;
    height: 52px;
    background: #ffffff;
    box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    border: none;
    z-index: 10000000000000000000 !important;
    &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 4px;
        height: 100%;
    }

    .el-message__icon.el-message__icon {
        &:before {
            content: "";
            width: 18px;
            height: 18px;
            display: inline-block;
            vertical-align: bottom;
        }
    }

    &.el-message--warning {
        &:before {
            background: #fa8c15;
        }
    }

    &.el-message--error {
        &:before {
            background: #e62412;
        }
    }

    &.el-message--info {
        &:before {
            color: #1479ff;
        }
    }

    .el-message__content.el-message__content {
        color: #333;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        line-height: 20px;
    }
}
</style>
