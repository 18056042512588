import Vue from "vue";
import App from "./App.vue";
import router from "./routers";
import ElementUI, { MessageBox, Message } from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "@/assets/styles/index.scss"; // global scss
import filters from "./utils/common.filter";
import moment from "vue-moment";
import echarts from "echarts";

Vue.prototype.$echarts = echarts;
Vue.prototype.$EventBus = new Vue();
Vue.config.productionTip = false;

MessageBox.install = () => {
    Vue.prototype.$confirm = MessageBox.confirm;
};
Message.install = () => {
    Vue.prototype.$message = Message;
};
Object.keys(filters).forEach(k => Vue.filter(k, filters[k]));
Vue.use(MessageBox);
Vue.use(Message);
Vue.use(ElementUI);
Vue.use(moment);

new Vue({
    router,
    render: h => h(App)
}).$mount("#app");
