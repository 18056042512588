<style scoped lang="scss"></style>
<template>
    <div class="wrapper">
        <side-bar></side-bar>
        <div class="wrapper_con">
            <m-header @contact="contact"></m-header>
            <main-page></main-page>
        </div>
    </div>
</template>

<script>
import SideBar from "./components/sideBar";
import MHeader from "./components/header";
import MainPage from "./components/main-page";
export default {
    components: {
        SideBar,
        MHeader,
        MainPage
    },
    data() {
        return {
            showContact: false,
            showAddkey: false
        };
    },
    methods: {
        contact(val) {
            this.showContact = val;
        },
        showUpdate(val) {
            this.showContact = val;
        },
        showAddkeyUpdate(val) {
            this.showAddkey = val;
        }
    }
};
</script>
