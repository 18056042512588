import Vue from "vue";
import VueRouter from "vue-router";
import layout from "@/views/layout/index";

Vue.use(VueRouter);

export const asyncRouterMap = [
    {
        path: "/",
        name: "dashbord",
        component: layout,
        redirect: "/dashbord/overview",
        children: [
            {
                path: "/dashbord/overview",
                name: "dashbord",
                icon: "el-icon-s-data",
                component: () => import("@/views/dashbord"),
                meta: { title: "统计分析", roles: ["admin", "user"] }
            }
        ]
    },
    {
        path: "/room",
        name: "room",
        redirect: "/room/management",
        component: layout,
        children: [
            {
                path: "management",
                name: "room",
                icon: "el-icon-s-platform",
                component: () => import("@/views/room"),
                meta: { title: "机房管理", roles: ["admin", "user"] }
            }
        ]
    },
    {
        path: "/set",
        name: "set",
        redirect: "/set",
        component: layout,
        icon: "el-icon-s-tools",
        meta: { title: "设置", roles: ["admin", "user"] },
        children: [
            {
                path: "account",
                name: "account",
                icon: "el-icon-user-solid",
                component: () => import("@/views/set/account"),
                meta: { title: "账号信息", roles: ["admin", "user"] }
            },
            {
                path: "userAccount",
                name: "userAccount",
                icon: "el-icon-menu",
                component: () => import("@/views/set/userAccount"),
                meta: { title: "用户账号管理", roles: ["admin"] }
            },
            {
                path: "log",
                name: "log",
                icon: "el-icon-s-order",
                component: () => import("@/views/set/log"),
                meta: { title: "操作日志", roles: ["admin"] }
            }
        ]
    },
    {
        path: "/login",
        name: "login",
        component: () => import("@/views/login"),
        hidden: true
    }
];

global.antRouter = asyncRouterMap;

const router = new VueRouter({
    mode: "history",
    routes: asyncRouterMap
});

router.beforeEach(async (to, from, next) => {
    // 角色权限
    const user = JSON.parse(localStorage.getItem("user"));
    const role = user && user.roles[0];
    if (role) {
        if (to.meta.roles.includes(role)) {
            next();
        } else {
            next({ path: "/dashbord/overview" });
        }
    }
    if (to.path !== "/login" && !localStorage.getItem("token")) {
        return next("/login");
    }

    next();
});

export default router;
