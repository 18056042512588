<template>
    <div class="sideBar">
        <div class="logo"></div>
        <el-scrollbar>
            <el-menu
                :default-active="activeMenu"
                :collapse-transition="true"
                class="el-menu-vertical-demo"
                background-color="#2c2f3a"
                text-color="#fff"
                mode="vertical"
            >
                <sidebar-item
                    v-for="item in routes"
                    :key="item.path"
                    :item="item"
                    :basePath="item.path"
                >
                </sidebar-item>
            </el-menu>
        </el-scrollbar>
    </div>
</template>
<!-- active-text-color 激活颜色 -->

<script>
import SidebarItem from "./item";
// import { mapGetters } from 'vuex'
export default {
    components: { SidebarItem },
    computed: {
        // ...mapGetters([]),
        activeMenu() {
            return this.$route.name;
        },
        routes() {
            const role = JSON.parse(localStorage.getItem("user")).roles[0];
            global.antRouter.forEach(item => {
                if (item.name === "set") {
                    item.children.forEach(subItem => {
                        if (!subItem.meta.roles.includes(role)) {
                            subItem.hidden = true;
                        }
                    });
                }
            });
            return global.antRouter;
        }
    }
};
</script>
