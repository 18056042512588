<template>
    <div class="sideItem" v-if="!item.hidden">
        <template v-if="hasOnlyChild(item.children, item) && (!childItem.children || childItem.noChild)">
            <router-link v-if="childItem.meta" :to="resolvePath(childItem.path)">
                <el-menu-item :index="item.name" style="padding-left: 25px;">
                    <i :class="childItem.icon" />
                    <span slot="title" class="title">{{
                        childItem.meta.title
                    }}</span>
                </el-menu-item>
            </router-link>
        </template>
        <el-submenu v-else :index="item.name" popper-append-to-body>
            <template slot="title">
                <i :class="item.icon" />
                <span class="title">{{ item.meta.title }}</span>
            </template>
            <sidebar-item
                class="subItem"
                v-for="child in item.children"
                :key="child.path"
                :item="child"
                :basePath="resolvePath(child.path)"
            >
            </sidebar-item>
        </el-submenu>
    </div>
</template>

<script>
// import { isAbsolutePath } from '@/utils/validate'
import path from "path";
export default {
    name: "SidebarItem",
    props: {
        item: {
            type: Object,
            required: true
        },
        basePath: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            childItem: null
        };
    },
    methods: {
        hasOnlyChild(children = [], item) {
            let newChildren = children.filter(obj => {
                return !obj.hidden;
            });
            if (newChildren.length === 1 && !item.meta) {
                this.childItem = newChildren[0];
                return true;
            }
            if (newChildren.length === 0) {
                this.childItem = { ...item, path: "", noChild: true };
                return true;
            }
            return false;
        },
        resolvePath(router) {
            // if (isAbsolutePath(router)) {
            //     return router
            // }
            // if (isAbsolutePath(this.basePath)) {
            //     return this.basePath
            // }
            return path.join(this.basePath, router);
        }
    }
};
</script>

<style lang="scss" scoped>
.sideItem {
    .title {
        font-size: 15px;
    }
}
</style>
